@font-face {
  font-family: "Montserrat";
  src: url('../assets/fonts/Montserrat.ttf') format("truetype-variations");
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url('../assets/fonts/Montserrat-Italic.ttf') format("truetype-variations");
  font-style: italic;
}
